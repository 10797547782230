import { addTemplate, log } from "core/utils";
const _ko = require("ko");
const geoInputTmpl = require("templates/geoLocationInput.html");

export default class GeoLocationInput {
    // properties
    hasRequestedLocation = false;
    tmplName: string = "geolocation-input";
    
    // Observables
    loading = _ko.observable(false);
    position = _ko.observable(null);
    valueInput = _ko.observable("");
    latlng = _ko.observable("");
    inputGeneratedName: any = _ko.observable('');

    // computed
    isReadOnly = _ko.computed( () => {
        return this.loading();
    });
    inputName = _ko.computed( () => {
        return this.inputGeneratedName() + '_addr';
    });
    hiddenInputName = _ko.computed( () => {
        return this.inputGeneratedName() + '_latlng';
    });

    constructor(name = 'generic') {
        addTemplate(this.tmplName, geoInputTmpl);
        this.setName(name);
    }

    getLocation() {
        if( this.hasRequestedLocation ){
            this.requestGeoSuccess( this.position());
        } else {
            this.hasRequestedLocation = true;
            // check for Geolocation support
            if (navigator.geolocation) {
                log("Geolocation is supported!");
                this.setLoading(true);
                navigator.geolocation.getCurrentPosition(this.requestGeoSuccess.bind(this),this.requestGeoError.bind(this));
            } else {
                log("Geolocation is not supported for this Browser/OS.");
            }
        }
    }

    protected requestGeoSuccess(position: GeolocationPosition) {
        log(position);
        this.setLoading(false);
        this.position(position);
        this.valueInput(`Mi ubicación actual [${this.position().coords.latitude},${this.position().coords.longitude}]`);
        this.latlng(`${this.position().coords.latitude},${this.position().coords.longitude}`);
    }

    protected requestGeoError( error: any) {
        log( error );
        this.setLoading(false);
    }

    setLoading( val: Boolean){
        this.loading(val);
    }

    setName( name: string ) {
        this.inputGeneratedName( name === 'generic' ? 'input-' + Date.now() : name  );
    }
}
