const _ko = require("ko");
const navTmpl = require("templates/navigation.html");
import {addTemplate} from "core/utils";

export default class Navigation {
    tmplName = "ccc-navigation";
    activeItem = _ko.observable("");

    //Computed properties
    isMapActive = _ko.computed( () => {
        return this.activeItem() === "map";
    });
    isBlogActive = _ko.computed( () => {
        return this.activeItem() === "blog";
    });
    isFormActive = _ko.computed( () => {
        return this.activeItem() === "form";
    });

    constructor() {
        addTemplate(this.tmplName,navTmpl);
        console.log("Adding navigation");
    }

    setActive( name: string ){
        this.activeItem(name);
    }
}