import { StorageObj } from "../interfaces/StorageObj";

export function addTemplate(name: string, val: string) {
    const existingElement = document.getElementById(name);

    //log("Adding a template");

    if (!existingElement) {
        const tmpl = document.createElement("template");
        tmpl.id = name;
        tmpl.innerHTML = val;

        //@ts-ignore
        document.querySelector("body").append(tmpl);
    }
}

export function pathContains(name: string) {
    return window.location.pathname.includes(name);
}

export function applyTemplateToElement(tpl: string, element: HTMLElement) {
    element.setAttribute("data-bind", `template: {name: '${tpl}'}`);
}

export function isDevMode() {
    return window.location.href.includes("localhost");
}

export function log(msg: any) {
    if( isDevMode() ){
        console.log(msg);
    }
}

export function getParameterByName(name: string, url = window.location.href): null | string {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results || !results[2]) return null;
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getPostSeoUrl() {
    const paths = window.location.pathname.split("/");
    if( paths.length === 3){
        return paths[2];
    }

    return null;
}

export function saveIntoStorage( namespace: string, data: any, expire=0, type="LS"): boolean {
    const storage = type === "SS" ? sessionStorage : localStorage;

    const dataToSave: StorageObj = {
        data: data,
        t: expire
    }

    storage.setItem( namespace, JSON.stringify(dataToSave));

    return true;
}

export function getFromStorage( namespace: string, type="LS"): StorageObj|null {
    const storage = type === "SS" ? sessionStorage : localStorage;

    const dataToRead = storage.getItem(namespace);
    const storageObj: StorageObj = JSON.parse(dataToRead);

    if( storageObj && (storageObj.t === 0 || storageObj.t > Date.now())){
        return storageObj;
    }

    return null;
}

/**
* http://stackoverflow.com/a/10997390/11236
*/
export function updateURLParameter(url: string, param: string, paramVal: string){
   var newAdditionalURL = "";
   var tempArray = url.split("?");
   var baseURL = tempArray[0];
   var additionalURL = tempArray[1];
   var temp = "";
   if (additionalURL) {
       tempArray = additionalURL.split("&");
       for (var i=0; i<tempArray.length; i++){
           if(tempArray[i].split('=')[0] != param){
               newAdditionalURL += temp + tempArray[i];
               temp = "&";
           }
       }
   }

   var rows_txt = temp + "" + param + "=" + paramVal;
   return baseURL + "?" + newAdditionalURL + rows_txt;
}