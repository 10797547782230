//@ts-ignore
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { SmaBlog } from "interfaces/SmaBlog";
import SmaContacto from "interfaces/SmaContacto";
import SmaEstado from "interfaces/SmaEstado";
import { SmaFotoacabador } from "interfaces/SmaFotoacabador";
import {saveIntoStorage,getFromStorage} from "core/utils";
import { StorageObj } from "../interfaces/StorageObj";

const firebaseConfig = {
    apiKey: "AIzaSyCRN5iYienMQERVCxeLzWi5IMdic4Smopc",
    authDomain: "volvamos-a-imprimir.firebaseapp.com",
    projectId: "volvamos-a-imprimir",
    storageBucket: "volvamos-a-imprimir.appspot.com",
    messagingSenderId: "644328692901",
    appId: "1:644328692901:web:7af0c4030f16a27b28814c",
};

export default class FirebaseGateway {
    app: any = null;
    db: any = null;

    constructor() {
        // Initialize Firebase
        this.app = firebase.initializeApp(firebaseConfig);
        this.db = this.app.firestore();
    }

    async getBlogList(limit = 10, startAfter: string = "") {
        const query: any = this.db
            .collection("blogs")
            .where("active", "==", true)
            .orderBy("publication", "desc")
            .limit(limit);
        if (startAfter !== "") {
            console.log(startAfter);
            query.startAfter(startAfter);
        }
        const querySnapshot: any = await query.get();

        const list: SmaBlog[] = [];
        querySnapshot.forEach((doc: any) => {
            list.push(this.convertDoc2SmaBlog(doc));
        });

        return list;
    }

    async getBlog(id: String): Promise<SmaBlog> {
        let blog: SmaBlog = null;
        const query: any = this.db.collection("blogs").doc(id);
        const doc = await query.get();
        if (doc.exists) {
            blog = this.convertDoc2SmaBlog(doc);
        }

        return blog;
    }

    async getPrevBlog(blog: SmaBlog): Promise<any> {
        const list: SmaBlog[] = [];
        const query: any = this.db
            .collection("blogs")
            .where("publication", ">", blog.publication.getTime())
            .where("active", "==", true)
            .limit(1)
            .orderBy("publication", "asc");

        const snapshot = await query.get();

        snapshot.forEach((doc: any) => {
            list.push(this.convertDoc2SmaBlog(doc));
        });
        return list.length === 1 ? list[0] : null;
    }

    async getNextBlog(blog: SmaBlog): Promise<any> {
        const list: SmaBlog[] = [];
        const query: any = this.db
            .collection("blogs")
            .where("publication", "<", blog.publication.getTime())
            .where("active", "==", true)
            .limit(1)
            .orderBy("publication", "desc");

        const snapshot = await query.get();

        snapshot.forEach((doc: any) => {
            list.push(this.convertDoc2SmaBlog(doc));
        });
        return list.length === 1 ? list[0] : null;
    }

    protected convertDoc2SmaBlog(doc: any) {
        const data = doc.data();
        return {
            id: doc.id,
            title: data.title,
            content: data.content,
            creation: new Date(data.creation),
            publication: new Date(data.publication),
            likes: data.likes,
            active: data.active,
            image: data.image,
            summary: data.summary,
            seoLink: data.seoLink,
            fbDoc: doc,
        };
    }

    protected convertDoc2SmaFotoacabador(doc: any) {
        const data = doc.data();
        return {
            id: doc.id,
            certified: data.certified,
            colonia: data.colonia,
            cp: data.cp,
            domicilio: data.domicilio,
            email: data.email,
            estado: data.estado,
            nombre_comercial: data.nombre_comercial,
            plus_code: (data.geo_info.detail &&
            data.geo_info.detail.plus_code &&
            data.geo_info.detail.plus_code.global_code
                ? data.geo_info.detail.plus_code.global_code
                : data.plus_code
            ).replace("+", "%2B"),
            poblacion: data.poblacion,
            razon_social: data.razon_social,
            telefonos: data.telefonos,
            website: data.website,
            latlng: data.geo_info.latlng,
        };
    }

    protected convertDoc2SmaEstado(doc: any) {
        const data = doc.data();
        return {
            id: doc.id,
            internal_id: data.internal_id,
            nombre: data.nombre,
            latlng: data.geo_info.latlng,
        };
    }

    async saveContacto(contacto: SmaContacto) {
        try {
            const id = "SMA" + Date.now();
            await this.db.collection("contactos").doc(id).set(contacto);

            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async getFotoacabadoresList() {
        const query: any = this.db
            .collection("fotoacabadores")
            .where("geo_info", "!=", null)
            .where("active", "==", true);

        const querySnapshot: any = await query.get();

        const list: SmaFotoacabador[] = [];
        querySnapshot.forEach((doc: any) => {
            list.push(this.convertDoc2SmaFotoacabador(doc));
        });

        return list;
    }

    async getEstadosList(): Promise<SmaEstado[]> {
        let list: SmaEstado[] = [];
        const cache: StorageObj = getFromStorage("sma_estados");
        if( cache ){
            list = cache.data;
        } else {
            const query: any = this.db.collection("estados").where("geo_info", "!=", null);
            const querySnapshot: any = await query.get();
            querySnapshot.forEach((doc: any) => {
                list.push(this.convertDoc2SmaEstado(doc));
            });
            // save into ls with expire time of ONE day
            saveIntoStorage("sma_estados",list, Date.now() + 86400000);
        }
        

        return list;
    }

    async getBlogBySeoUrl(name: string): Promise<SmaBlog> {
        let blog: SmaBlog = null;
        const query: any = this.db.collection("blogs").where("seoLink", "==", name);
        const querySnapshot: any = await query.get();
        querySnapshot.forEach((doc: any) => {
            blog = this.convertDoc2SmaBlog(doc);
        });

        return blog;
    }
}
