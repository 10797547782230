import * as Utils from "core/utils";
import FirebaseGateway from "core/firebaseGateway";
import { SmaBlog } from "interfaces/SmaBlog";
import Navigation from "components/navigation";
// import OptionSelector from "components/optionSelector";
import GeoLocationInput from "components/geoLocationInput";
import BlogCarousel from "components/blogCarousel";
// import SmaEstado from "interfaces/SmaEstado";
const _ko = require("ko");

$(document).ready(async () => {
    const nav = new Navigation();
    $("[data-ko=navigation]").each((idx, element) => {
        Utils.applyTemplateToElement(nav.tmplName, element);
        _ko.applyBindings(nav, element);
    });

    const fb = new FirebaseGateway();

    const blogs: SmaBlog[] = await fb.getBlogList(3);
    //console.log(blogs);
    // const estados: SmaEstado[] = await fb.getEstadosList();
    // const options = estados.map(( estado ) => {
    //     return {
    //         value: estado.internal_id,
    //         title: estado.nombre
    //     }
    // });
    // //console.log(options);
    // const estadosSelect = new OptionSelector(options,'search_estado');
    // estadosSelect.setPlaceholder("Seleccione un Estado");
    // $("[data-ko=estados-select]").each((idx, element) => {
    //     Utils.applyTemplateToElement(estadosSelect.tmplName, element);
    //     _ko.applyBindings(estadosSelect, element);
    // });

    const glinput = new GeoLocationInput('search_geolocation');
    $("[data-ko=location-input]").each((idx, element) => {
        Utils.applyTemplateToElement(glinput.tmplName, element);
        _ko.applyBindings(glinput, element);
    });

    const blogC = new BlogCarousel(blogs);
    $("[data-ko=blog-carousel]").each((idx, element) => {
        $(element).attr("data-bind", "template: {name: 'blog-carousel'}");
        _ko.applyBindings(blogC, element);
    });
});
