const _ko = require("ko");
const optSelTmp = require("templates/blogCarousel.html");
import {addTemplate} from "core/utils";
import { SmaBlog } from "interfaces/SmaBlog";

export default class OptionSelector {
    list: any = _ko.observableArray([]);

    constructor( list: SmaBlog[]) {
        addTemplate('blog-carousel',optSelTmp);
        this.setList(list);
        //console.log(this.list());
    }

    setList( list: SmaBlog[] )
    {
        this.list(list);
    }

    isFirst(idx: any){
        return idx() === 0;
    }

    buildPostLink = ( seoLink: string ) => {
        return "entrada/" + seoLink;
        //return "post.html?id=" + id;
    }
}